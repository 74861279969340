/*
Theme Name:
Author: yaminncco

Colors:
	Body 		  : #868F9B
	Headers 	: #10161A
	Primary 	: #6195FF
	Dark      : #FCFCFF
	Grey 		  : #F4F4F4 #FAFAFA #EEE

Fonts: Montserrat Varela Round

Table OF Contents
------------------------------------
1 > General
2 > Logo
3 > Navigation
4 > Header
5 > About
6 > Portfolio
7 > Services
8 >  Why choose us
9 >  Numbers
10 > Pricing
11 > Testimonial
12 > Team
13 > Blog
14 > Blog post
15 > Blog sidebar
16 > Contact
17 > Footer
18 > Responsive
19 > Owl theme
20 > Back to top
21 > Preloader

------------------------------------*/

/*------------------------------------*\
	General
\*------------------------------------*/


/* -- typography -- */

body{
    font-family: 'Varela Round', 'sans-serif' !important;
    font-size: 16px;
    line-height: 1.6;
    overflow-x: hidden;
    color: #868F9B;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 20px;
    color: #10161A;
}

h1 {
    font-size: 54px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 21px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

a {
    color: #6195FF;
    text-decoration: none;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
}

a:hover, a:focus {
    text-decoration: none;
    outline: none;
    opacity: 0.8;
    color: #6195FF;
}

.main-color {
    color: #6195FF;
}

.white-text {
    color: #FFF;
}

::-moz-selection {
    background-color: #6195FF;
    color: #FFF;
}

::selection {
    background-color: #6195FF;
    color: #FFF;
}

ul, ol {
    margin: 0;
    padding: 0;
    list-style: none
}

/* -- section  -- */

.section {
    position: relative;
}

.md-padding {
    padding-top: 120px;
    padding-bottom: 120px;
}

.sm-padding {
    padding-top: 60px;
    padding-bottom: 60px;
}


/* --  background section  -- */

.bg-grey {
    background-color: #FAFAFA;
    border-top: 1px solid #EEE;
    border-bottom: 1px solid #EEE;
}

.bg-dark {
    background-color: #1C1D21;
}


/* --  background image section  -- */

.bg-img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.bg-img .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5 ;
    background: #1C1D21;
}


/* --  section header  -- */

.section-header {
    position: relative;
    margin-bottom: 60px;
}

.section-header .title {
    text-transform: capitalize;
}

.title:after {
    content:"";
  	display:block;
  	height:4px;
  	width:40px;
  	background-color: #6195FF;
    margin-top: 20px;
}

.text-center .title:after {
    margin: 20px auto 0px;
}

/* --  Input  -- */

input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="date"], input[type="url"], input[type="tel"], textarea {
    height: 40px;
    width: 100%;
    border: none;
	  background: #F4F4F4;
    border-bottom: 2px solid #EEE;
    color: #354052;
    padding: 0px 10px;
    opacity: 0.5;
    -webkit-transition: 0.2s border-color, 0.2s opacity;
    transition: 0.2s border-color, 0.2s opacity;
}

textarea {
    padding: 10px 10px;
    min-height: 80px;
    resize: vertical;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="number"]:focus, input[type="date"]:focus, input[type="url"]:focus, input[type="tel"]:focus, textarea:focus {
    border-color: #6195FF;
    opacity: 1;
}

/* --  Buttons  -- */

.main-btn, .white-btn, .outline-btn {
    display: inline-block;
    padding: 10px 35px;
    margin: 3px;
    border: 2px solid transparent;
    border-radius: 3px;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
}

.main-btn {
    background: #6195FF;
    color: #FFF;
}

.white-btn {
    background: #FFF;
    color: #10161A !important;
}

.outline-btn {
    background: transparent;
    color: #6195FF !important;
    border-color: #6195FF;
}

.main-btn:hover, .white-btn:hover, .outline-btn:hover {
    opacity: 0.8;
}


/*------------------------------------*\
	Logo
\*------------------------------------*/

.navbar-brand {
    padding: 0;
}

.navbar-brand .logo, .navbar-brand .logo-alt {
    max-height: 50px;
    display: block;
}

#nav:not(.nav-transparent):not(.fixed-nav) .navbar-brand .logo-alt {
	display: none;
}

#nav.nav-transparent:not(.fixed-nav) .navbar-brand .logo {
	display: none;
}

#nav.fixed-nav .navbar-brand .logo-alt {
    display: none;
}

@media only screen and (max-width: 767px) {
    #nav.nav-transparent .navbar-brand .logo-alt {
        display: none !important;
    }
    #nav.nav-transparent .navbar-brand .logo {
        display: block !important;
    }
}


/*------------------------------------*\
	Navigation
\*------------------------------------*/

#nav {
    padding: 10px 20px;
    background: #FFF;
    -webkit-transition: 0.2s padding;
    transition: 0.2s padding;
    z-index: 999;
    height: 70px;

}

#nav.navbar {
    border: none;
    border-radius: 0;
    margin-bottom: 0px;
}

#nav.fixed-nav {
    position: fixed;
    left: 0;
    right: 0;
    padding: 0px 0px;
    background-color: #FFF !important;
    border-bottom: 1px solid #EEE;
}

#nav.nav-transparent {
    background: transparent;
}


/* -- default nav -- */

@media only screen and (min-width: 768px) {
    .main-nav li {
        padding: 0px 15px;
    }
    .main-nav li a {
        font-size: 14px;
        -webkit-transition: 0.2s color;
        transition: 0.2s color;
    }
    .main-nav>li>a {
        color: #10161A;
        padding: 15px 0px;
    }
    #nav.nav-transparent:not(.fixed-nav) .main-nav>li>a {
        color: #fff;
    }
    .main-nav>li>a:hover, .main-nav>li>a:focus, .main-nav>li.active>a {
        background: transparent;
        color: #6195FF;
    }
    .main-nav>li>a:after {
        content: "";
        display: block;
        background-color: #6195FF;
        height: 2px;
        width: 0%;
        -webkit-transition: 0.2s width;
        transition: 0.2s width;
    }
    .main-nav>li>a:hover:after, .main-nav>li.active>a:after {
        width: 100%;
    }
    /* dropdown */
    .has-dropdown {
        position: relative;
    }
    .has-dropdown>a:before {
        font-family: 'FontAwesome';
        content: "\f054";
        font-size: 6px;
        margin-left: 6px;
        float: right;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transition: 0.2s transform;
        transition: 0.2s transform;
    }
    .dropdown {
        position: absolute;
        right: -50%;
        top: 0;
        background-color: #6195FF;
        width: 200px;
        -webkit-box-shadow: 0px 5px 5px -5px rgba(53, 64, 82, 0.2);
        box-shadow: 0px 5px 5px -5px rgba(53, 64, 82, 0.2);
        -webkit-transform: translateY(15px) translateX(50%);
        -ms-transform: translateY(15px) translateX(50%);
        transform: translateY(15px) translateX(50%);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
    }
    .main-nav>.has-dropdown>.dropdown {
        top: 100%;
        right: 50%;
    }
    .main-nav>.has-dropdown>.dropdown .dropdown.dropdown-left {
        right: 150%;
    }
    .dropdown li a {
        display: block;
        color: #FFF;
        border-top: 1px solid rgba(250, 250, 250, 0.1);
        padding: 10px 0px;
    }
    .dropdown li:nth-child(1) a {
        border-top: none;
    }
    .has-dropdown:hover>.dropdown {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0px) translateX(50%);
        -ms-transform: translateY(0px) translateX(50%);
        transform: translateY(0px) translateX(50%);
    }
    .has-dropdown:hover>a:before {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    .nav-collapse {
        display: none;
    }
}


/* -- mobile nav -- */

@media only screen and (max-width: 767px) {
    #nav {
        padding: 0px 0px;
    }
    #nav.nav-transparent {
        background: #FFF;
    }
    .main-nav {
        position: fixed;
        right: 0;
        height: calc(100vh - 80px);
        -webkit-box-shadow: 0px 80px 0px 0px #1C1D21;
        box-shadow: 0px 80px 0px 0px #1C1D21;
        max-width: 250px;
        width: 0%;
        -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
        margin: 0;
        overflow-y: auto;
        background: #1C1D21;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
    }
    #nav.open .main-nav {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
        width: 100%;
    }
    .main-nav li {
        border-top: 1px solid rgba(250, 250, 250, 0.1);
    }
    .main-nav li a {
        display: block;
        color: #FFF;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
    }
    .main-nav>li.active {
        border-left: 6px solid #6195FF;
    }
    .main-nav li a:hover, .main-nav li a:focus {
        background-color: #6195FF;
        color: #FFF;
        opacity: 1;
    }
    .has-dropdown>a:after {
        content: "\f054";
        font-family: 'FontAwesome';
        float: right;
        -webkit-transition: 0.2s -webkit-transform;
        transition: 0.2s -webkit-transform;
        transition: 0.2s transform;
        transition: 0.2s transform, 0.2s -webkit-transform;
    }
    .dropdown {
        opacity: 0;
        visibility: hidden;
        height: 0;
        background: rgba(250, 250, 250, 0.1);
    }
    .dropdown li a {
        padding: 6px 10px;
    }
    .has-dropdown.open-drop>a:after {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .has-dropdown.open-drop>.dropdown {
        opacity: 1;
        visibility: visible;
        height: auto;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
    }
}


/* -- nav btn collapse -- */

.btn-outline-black {
    border: 2px solid #000;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    border-radius: 0px;
    padding: 10px 20px;
    color: #000;
}
.btn-outline-black:hover {
    color: #fff;
    background: #000;
    border-color: #000;
  }
  
  .btn-outline-white {
    border: 2px solid #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    border-radius: 0px;
    padding: 10px 20px;
    color: #fff;
  }
  
  .btn-outline-white:hover {
    color: #000;
    background: #fff;
    border-color: #fff;
  }
  
.nav-collapse {
    position: relative;
    float: right;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    z-index: 99999;
}

.nav-collapse span {
    display: block;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
}

.nav-collapse span:before, .nav-collapse span:after {
    content: "";
    display: block;
}

.nav-collapse span, .nav-collapse span:before, .nav-collapse span:after {
    height: 4px;
    background: #10161A;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.nav-collapse span:before {
    -webkit-transform: translate(0%, 10px);
    -ms-transform: translate(0%, 10px);
    transform: translate(0%, 10px);
}

.nav-collapse span:after {
    -webkit-transform: translate(0%, -14px);
    -ms-transform: translate(0%, -14px);
    transform: translate(0%, -14px);
}

#nav.open .nav-collapse span {
    background: transparent;
}

#nav.open .nav-collapse span:before {
    -webkit-transform: translateY(0px) rotate(-135deg);
    -ms-transform: translateY(0px) rotate(-135deg);
    transform: translateY(0px) rotate(-135deg);
}

#nav.open .nav-collapse span:after {
    -webkit-transform: translateY(-4px) rotate(135deg);
    -ms-transform: translateY(-4px) rotate(135deg);
    transform: translateY(-4px) rotate(135deg);
}


/*------------------------------------*\
	Header
\*------------------------------------*/

header {
    position: relative;
}

#home {
    height: 100vh;
}

#home .home-wrapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
}

.home-content h1 {
  text-transform: uppercase;
}
.home-content button {
  margin-top: 20px;
}

.header-wrapper h2 {
    display: inline-block;
    margin-bottom: 0px;
}

.header-wrapper .breadcrumb {
    float: right;
    background: transparent;
    margin-bottom: 0px;
}

.header-wrapper .breadcrumb .breadcrumb-item.active {
    color: #868F9B;
}

.breadcrumb>li+li:before {
    color: #868F9B;
}


/*------------------------------------*\
	About
\*------------------------------------*/

.about {
    position: relative;
    text-align: center;
    padding: 10px 10px;
    /* border: 1px solid #EEE; */
    margin: 15px 0px;
}

.about i {
    font-size: 36px;
    color: #6195FF;
    margin-bottom: 20px;
}

/* .about:after {
    content: "";
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    z-index: -1;
    -webkit-transition: 0.2s width;
    transition: 0.2s width;
} */

/* .about:hover:after {
    width: 100%;
} */

.about h3 {
    -webkit-transition: 0.2s color;
    transition: 0.2s color;
}
/* 
.about:hover h3 {
    color: #fff;
} */
.about i{
    color:black;
}
/* .about:hover i {
    color: #fff;
}
.about:hover p {
    color: #fff;
} */

/*------------------------------------*\
	Portfolio
\*------------------------------------*/

.work {
    position: relative;
    padding: 20px;
}

.work>img {
  width: 100%;
}

.work .overlay {
    background: #1C1D21;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.work:hover .overlay {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    opacity: 0.8;
}

.work .work-content {
    position: absolute;
    left: 25px;
    right: 25px;
    top: 50%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.work .work-content h3 {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    color: #FFF;
    margin-bottom: 10px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.work:hover .work-content h3 {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.work .work-content span {
    display: block;
    text-transform: uppercase;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    color: #6195FF;
    margin-bottom: 5px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.work:hover .work-content span {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.work .work-link {
    text-align: center;
    margin-top: 20px;
    opacity: 0;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
}

.work .work-link a {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #6195FF;
    color: #FFF;
    line-height: 50px;
    text-align: center;
}

.work:hover .work-link {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    opacity: 1;
}


/*------------------------------------*\
	Services
\*------------------------------------*/

.column {
    flex: 50%;
    max-width: 50%;
    padding: 0 4px;
  }
  @media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
.service {
    position: relative;
    padding: 40px 20px 40px 70px;
    margin: 15px 0px;
    border: 1px solid #EEE;
    height: 260px;
}

.service i {
    position: absolute;
    left: 20px;
    text-align: center;
    font-size: 32px;
    color: #6195FF;
    border-radius: 50%;
}

.service:after {
    content: "";
    background-color: #1C1D21;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    z-index: -1;
    -webkit-transition: 0.2s width;
    transition: 0.2s width;
}

/* .service:hover:after {
    width: 100%;
} */

.service h3 {
  -webkit-transition: 0.2s color;
  transition: 0.2s color;
}

/* .service:hover h3 {
    color: #fff;
} */
.service i {
    color: #10161A;
}
/* .service:hover i {
    color: white;
} */

/*------------------------------------*\
	Why choose us
\*------------------------------------*/

.feature {
    margin: 15px 0px;
}
.feature i {
    float: left;
    padding: 5px;
    border-radius: 50%;
    color: #6195FF;
    border: 1px solid #6195FF;
    margin-right: 5px;
}

/*------------------------------------*\
	Numbers
\*------------------------------------*/

.number {
    text-align: center;
    margin: 15px 0px;
}

.number i {
    color: #6195FF;
    font-size: 36px;
    margin-bottom: 20px;
}

.number h3 {
    font-size: 36px;
    margin-bottom: 10px;
}


/*------------------------------------*\
	Pricing
\*------------------------------------*/

.pricing {
    position: relative;
    text-align: center;
    /* border: 1px solid #EEE; */
    background-color: #FFF;
    z-index: 11;
    margin: 15px 0px;
}
  /* * {
    background: #000 !important;
    color: #0f0 !important;
    
    outline: solid #f00 1px !important;
    } */

.pricing::after {
    content: "";
    background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0%;
    z-index: -1;
    -webkit-transition: 0.2s height;
    transition: 0.2s height;
}

.pricing:hover:after {
    height: 100%;
}

.pricing .price-head {
    position: relative;
    margin-bottom: 20px;
}

.pricing .price-title {
    display: block;
    padding: 40px 0px 20px;
    text-transform: uppercase;
    -webkit-transition: 0.2s color;
    transition: 0.2s color;
}

.pricing:hover .price-title {
    color: #6195FF;
}

.pricing .price {
    position: relative;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
    margin: auto;
    border-radius: 50%;
    border: 2px solid #6195FF;
}

.pricing .price h3 {
  font-size: 42px;
  margin: 0px;
  -webkit-transition: 0.2s color;
  transition: 0.2s color;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
}

.pricing:hover .price h3 {
    color: #fff;
}

.pricing .duration {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    color: #10161A;
    -webkit-transition: 0.2s color;
    transition: 0.2s color;
}

.pricing:hover .duration {
    color: #fff;
}

.pricing .price-btn {
    padding-top: 20px;
    padding-bottom: 40px;
}

/*------------------------------------*\
	Testimonial
\*------------------------------------*/

.testimonial {
    margin: 15px 0px;
}

.testimonial-meta {
    position: relative;
    padding-left: 90px;
    height: 70px;
    margin-bottom: 20px;
    padding-top: 10px;
}

.testimonial img {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
}

.testimonial h3 {
    margin-bottom: 5px;
}

.testimonial span {
    font-size: 14px;
    color: #6195FF;
    text-transform: uppercase;
}


/*------------------------------------*\
	Team
\*------------------------------------*/

.team {
    position: relative;
    background-color: #F4F4F4;
    padding: 40px 20px;
    margin: 15px 0px;
}

.team::after {
    content: "";
    background-color: #1C1D21;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0%;
    z-index: 1;
    -webkit-transition: 0.2s height;
    transition: 0.2s height;
}

.team:hover:after {
    height: 100%;
}

.team-img {
    position: relative;
    margin-bottom: 20px;
    z-index: 11;
}

.team-img>img {
  width: 100%;
}

.team .overlay {
    background: #1C1D21;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
}

.team:hover .overlay {
    opacity: 0.8;
}

.team .team-content {
    text-align: center;
    position: relative;
    z-index: 11;
}

.team .team-content h3 {
    margin-bottom: 10px;
    -webkit-transition: 0.2s color;
    transition: 0.2s color;
}

.team .team-content span {
    font-size: 14px;
    text-transform: uppercase;
    -webkit-transition: 0.2s color;
    transition: 0.2s color;
}

.team:hover .team-content h3 {
    color: #FFF;
}

.team:hover .team-content span {
    color: #6195FF;
}

.team .team-social {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
}

.team .team-social a {
    display: block;
    line-height: 50px;
    width: 50px;
    text-align: center;
    background-color: #6195FF;
    color: #FFF;
}

.team:hover .team-social {
    opacity: 1;
}


/*------------------------------------*\
	Blog
\*------------------------------------*/

.blog {
    background-color: #FFF;
    margin: 15px 0px;
}

.blog .blog-content {
    padding: 20px 20px 40px;
}

.blog .blog-meta {
    margin-bottom: 20px;
}

.blog .blog-meta li {
    display: inline-block;
    font-size: 14px;
    color: #10161A;
    margin-right: 10px;
}

.blog .blog-meta li i {
    color: #6195FF;
    margin-right: 5px;
}

/*------------------------------------*\
	Blog post
\*------------------------------------*/

#main .blog .blog-content {
  padding: 20px 0px 0px;
}

#main .blog {
  margin-top: 0px;
}

/* -- blog tags -- */

.blog-tags {
    margin: 40px 0px;
}

.blog-tags h5 {
    margin-bottom: 0;
    display: inline-block;
}

.blog-tags a {
    display: inline-block;
    padding: 6px 13px;
    font-size: 14px;
    margin: 2px 0px;
    background: #F4F4F4;
    color: #10161A;
}

.blog-tags a:hover {
  color: #FFF;
  background-color: #6195FF;
}

.blog-tags a i {
    margin-right: 3px;
    color: #6195FF;
}

.blog-tags a:hover i {
  color: #FFF;
}


/* -- blog author -- */

.blog-author {
    margin: 40px 0px;
}

.blog-author .media .media-left {
    padding-right: 20px;
}

.blog-author .media {
    padding: 20px;
    border: 1px solid #EEE;
}

.blog-author .media .media-heading {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #EEE;
}

.blog-author .media .media-heading h3 {
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
}

.blog-author .media .media-heading .author-social {
  float: right;
}

.blog-author .author-social a {
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 3px;
    margin-left: 5px;
    color: #FFF;
    background-color: #6195FF;
}

/* -- blog comments -- */

.blog-comments {
    margin: 40px 0px;
}

.blog-comments .media {
    padding: 20px;
    background-color: #FFF;
    border-top: 1px solid #EEE;
    border-bottom: 0px;
    border-right: 1px solid #EEE;
    border-left: 1px solid #EEE;
    margin-top: 0px;
}

.blog-comments .media:first-child {
    border-bottom: 0px;
}

.blog-comments .media:last-child {
    border-bottom: 1px solid #EEE;
}

.blog-comments .media .media-left {
  padding-right: 20px;
}

.blog-comments .media .media-body .media-heading {
    text-transform: uppercase;
    margin-bottom:10px;
}

.blog-comments .media .media-body .media-heading .time {
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
    color: #868F9B;
}

.blog-comments .media .media-body .media-heading .reply {
    float: right;
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
}

.blog-comments .media.author {
    background-color: #F4F4F4;
    border-color: #6195FF;
    border-bottom: 1px solid #6195FF;
}
.blog-comments .media.author>.media-body>.media-heading {
    color: #6195FF;
}

.blog-comments .media.author + .media {
    border-top: 0px;
}

/* blog reply form */

.reply-form {
    margin: 40px 0px;
}

.reply-form form .input, .reply-form form .input , .reply-form form textarea {
	margin-bottom:20px;
}

.reply-form form .input, .reply-form form .input {
    width: calc(50% - 10px);
    display: inline-block;
}

.reply-form form .input:nth-child(2) {
    margin-left: 15px;
}


/*------------------------------------*\
	Blog sidebar
\*------------------------------------*/

#aside .widget {
    margin-bottom: 40px;
}

.widget h3 {
  text-transform: uppercase;
}

/* -- search sidebar -- */

#aside .widget-search {
    position: relative;
}

#aside .widget-search .search-input {
    padding-right: 50px;
}

#aside .widget-search .search-btn {
    position: absolute;
    right: 0px;
	  bottom: 0px;
    width: 40px;
    height: 40px;
    border: none;
    line-height: 40px;
    background-color: transparent;
    color: #6195FF;
}

/* -- category sidebar -- */

.widget-category a {
    display: block;
    font-size: 14px;
    color: #354052;
    border-bottom: 1px solid #EEE;
    padding: 5px;
}

.widget-category a:nth-child(1) {
  border-top: 1px solid #EEE;
}

.widget-category a span {
    float: right;
    color: #6195FF;
}

.widget-category a:hover {
    color: #6195FF;
}

/* -- tags sidebar -- */

.widget-tags a {
  display: inline-block;
  padding: 6px 13px;
  font-size: 14px;
  margin: 2px 0px;
  background: #F4F4F4;
  color: #10161A;
}

.widget-tags a:hover {
  color: #FFF;
  background-color: #6195FF;
}

/* -- posts sidebar -- */

.widget-post {
    min-height: 70px;
    margin-bottom: 25px;
}

.widget-post img {
    display: block;
    float: left;
    margin-right: 10px;
    margin-top: 5px;
}

.widget-post a {
    display: block;
    color: #10161A;
}

.widget-post a:hover {
    color: #6195FF;
}

.widget-post .blog-meta {
    display: inline-block;
}

.widget-post .blog-meta li {
    display: inline-block;
    margin-right: 5px;
    color: #6195FF;
    font-size: 12px;
}

.widget-post li i {
    color: #6195FF;
    margin-right: 5px;
}



/*------------------------------------*\
	Contact
\*------------------------------------*/

.contact {
    margin: 15px 0px;
    text-align: center;
}

.contact i {
    font-size: 36px;
    color: #6195FF;
    margin-bottom: 20px;
}

.contact-form {
    text-align: center;
    margin-top: 40px;
}

.contact-form .input {
    margin-bottom: 20px;
}


.contact-form .input:nth-child(1), .contact-form .input:nth-child(2) {
    width: calc(50% - 10px);
}

.contact-form .input:nth-child(2) {
    margin-left: 15px;
}

/*------------------------------------*\
	Footer
\*------------------------------------*/

#footer {
    position: relative;
}

.footer-logo {
    text-align: center;
    margin-bottom: 40px;
}

.footer-logo>a>img {
    max-height: 80px;
}

.footer-follow {
    text-align: center;
    margin-bottom: 20px;
}

.footer-follow li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 13px;
}

.footer-follow li a {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 3px;
  background-color: #6195FF;
  color:#FFF;
}

.footer-copyright p {
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
}


/*------------------------------------*\
	Responsive
\*------------------------------------*/


@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {
  .section-header h2.title {
  		font-size:31.5px;
	}

  .main-btn , .default-btn , .outline-btn , .white-btn  {
  		padding: 8px 22px;
  		font-size:14px;
	}

  .home-content h1 {
		font-size:36px;
	}

  .header-wrapper h2 {
      margin-bottom: 20px;
      text-align: center;
      display: block;
  }

  .header-wrapper .breadcrumb {
      float: none;
      text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  #portfolio [class*='col-xs'] {
		  width:100%;
	}

  #numbers [class*='col-xs'] {
		  width:100%;
	}

  .contact-form .input:nth-child(1), .contact-form .input:nth-child(2) {
      width: 100%;
  }
  .contact-form .input:nth-child(2) {
      margin-left: 0px;
  }

  .reply-form form .input, .reply-form form .input {
      width: 100%;
  }
  .reply-form form .input:nth-child(2) {
      margin-left: 0px;
  }



  .blog-author .media .media-left {
      display: block;
      padding-right: 0;
      margin-bottom: 20px;
  }
  .blog-author .media {
      text-align: center;
  }
  .blog-author .media .media-heading .author-social {
      margin-top: 10px;
      float: none;
  }
  .blog-author .media .media-left img {
      margin: auto;
  }

  .blog-comments .media .media {
      margin:0px -15px;
  }
}



/*------------------------------------*\
	Owl theme
\*------------------------------------*/

/* -- dots -- */

.owl-theme .owl-dots .owl-dot span {
    border: none;
    background: #EEE;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.owl-theme .owl-dots .owl-dot:hover span {
    background: #6195FF;
}
.owl-theme .owl-dots .owl-dot.active span {
	  background: #6195FF;
	  width:20px;
}

/* -- nav -- */

.owl-theme .owl-nav {
    opacity: 0;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
}

.owl-theme:hover .owl-nav {
    opacity: 1;
}

.owl-theme .owl-nav [class*='owl-'] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    background: #6195FF;
    color: #FFF;
  	padding: 0px;
  	width: 50px;
  	height: 50px;
  	border-radius:3px;
  	line-height: 50px;
  	margin: 0;
}

.owl-theme .owl-prev {
    left: 0px;
}

.owl-theme .owl-next {
    right: 0px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
    opacity: 0.8;
    background: #6195FF;
}


/*------------------------------------*\
	Back to top
\*------------------------------------*/

#back-to-top {
  	display:none;
  	position: fixed;
  	bottom: 20px;
  	right: 20px;
  	width: 50px;
  	height: 50px;
  	line-height: 50px;
  	text-align: center;
  	background: #6195FF;
  	border-radius:3px;
  	color: #FFF;
  	z-index: 9999;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
  	cursor: pointer;
}

#back-to-top:after {
    content: "\f106";
    font-family: 'FontAwesome';
}

#back-to-top:hover {
    opacity: 0.8;
}

/*------------------------------------*\
	Preloader
\*------------------------------------*/


#preloader {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #FFF;
    z-index: 99999;
}

.preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.preloader span {
    display: inline-block;
    background-color: #6195FF;
    width: 25px;
    height: 25px;
    -webkit-animation: 1s preload ease-in-out infinite;
            animation: preload 1s ease-in-out infinite;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    border-radius:50%;
}

.preloader span:nth-child(1) {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

.preloader span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
}

.preloader span:nth-child(3) {
    -webkit-animation-delay: 0.15s;
            animation-delay: 0.15s;
}

.preloader span:nth-child(4) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
}

@-webkit-keyframes preload {
	0% {
	   -webkit-transform:scale(0);
	           transform:scale(0);
	}
  50% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
  100% {
    -webkit-transform:scale(0);
            transform:scale(0);
  }
}

@keyframes preload {
	0% {
	   -webkit-transform:scale(0);
	           transform:scale(0);
	}
  50% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
  100% {
    -webkit-transform:scale(0);
            transform:scale(0);
  }
}

footer {
    margin-top: -6px;
    background-color: #373a3c;
    padding: 50px 0px;
    }
    footer .logo em {
    font-style: normal;
    font-size: 28px;
    color: #f7c552;
    }
    footer .logo-ft {
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    color: #fff!important;
    font-size: 21px;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: none;
    letter-spacing: 1px;
    }
    footer .logo p {
    font-weight: 300;
    font-size: 12px;
    color: #fff;
    margin-bottom: -10px;
    letter-spacing: 0.5px;
    }
    footer .logo span {
    font-weight: 600;
    color: #f7c552;
    }
    footer .logo a {
    color: #FFF;
    }
    footer .logo a:hover {
    color: #9F0;
    }
    footer .lokasi h4 {
    margin-top: 0px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    }
    footer .lokasi ul li {
    font-size: 13px;
    color: #fff;
    display: inline-block;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    }
    footer .lokasi ul li:first-child {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(250, 250, 250, 0.1);
    }
    footer .contact-info h4 {
    margin-top: 0px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    }
    footer .contact-info ul li {
    font-size: 13px;
    color: #fff;
    display: inline-block;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 300;
    margin-bottom: -15px;
    }
    footer .contact-info ul li em {
    font-style: normal;
    font-weight: 500;
    }
    footer .kasicall h4 {
    margin-top: 0px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 35px;
    }
    footer .kasicall ul li {
    display: inline-block;
    margin-right: 2px;
    }
    footer .kasicall ul li a {
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    color: #373a3c;
    font-size: 15px;
    transition: all 0.3s;
    }
    footer .kasicall ul li a:hover {
    background-color: #f7c552;
    }
    /* RESPONSIVE */
    @media (max-width: 991px){
    .baner-content .text-content p {
    padding: 0px 15%;
    }
    #about .service-item {
    margin-bottom: 80px;
    } 
    #portfolio .section-heading {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #33c1cf;
    height: 505px;
    margin-left: -15px;
    padding-left: 0vh;
    margin-right: -15px;
    padding-right: 0vh;
    overflow: hidden;
    }
    #portfolio .section-heading .line-dec {
    margin: 20px auto 15px auto;
    }
    #portfolio .section-heading p {
    padding: 0px 20px;
    }
    .projects-holder {
    text-align: center;
    padding: 15px 0px;
    }
    #portfolio .project-item {
    margin: 15px 0px;
    }
    #blog .section-heading {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(0, 0, 0, 0.5);
    margin-left: -15px;
    padding-left: 0vh;
    margin-right: -15px;
    padding-right: 0vh;
    overflow: hidden!important;
    }
    #blog .section-heading .line-dec {
    margin: 20px auto 15px auto;
    }
    #blog .section-heading p {
    padding: 0px 30px;
    }
    .tabs {
    padding: 0px 45px;
    }
    #first-tab-group {
    margin-bottom: 30px;
    }
    #contact-us .section-heading p {
    padding: 0px 10%;
    }
    footer {
    text-align: center;
    }
    footer .logo {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
    }
    footer .lokasi {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
    }
    footer .contact-info {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
    }
    footer .contact-info ul li {
    margin-right: 10px;
    margin-left: 10px;
    }
    }
    @media (max-width: 768px){
    #statis{
    padding:20px;
    margin:20px;
    }
    .baner-content .text-content p {
    padding: 0px 10%;
    }
    #main-nav {
    margin-left: -15px;
    margin-right: -15px;
    border-top: none;
    margin-top: 15px;
    background-color: rgba(250, 250, 250, 0.95);
    }
    .navbar-nav {
    margin-top: 0px;
    margin-bottom: 0px;
    }
    .navbar-inverse .navbar-nav>li {
    margin-left: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .navbar-inverse .navbar-nav>li:last-child {
    border-bottom: none;
    }
    .navbar-inverse .navbar-nav>li>a {
    font-family: 'Roboto', sans-serif!important;
    line-height: 55px;
    color: #343434!important;
    }
    }
    @media (max-width: 385px){
    #statis{
    padding:20px;
    margin:20px;
    }
    footer .lokasi ul li {
    display: block;
    }
    footer .lokasi ul li:first-child {
    margin-right: 0px;
    padding-right: 0px;
    border-right: none;
    }

   }
.img-dotted-bg {
position: relative;
}

.section-title {
text-transform: uppercase;
font-weight: 300;
}
.btn-outline-black {
border: 2px solid #000;
text-transform: uppercase;
font-size: 14px;
font-weight: bold;
border-radius: 0px;
padding: 10px 20px;
color: #000;
}	
.divider {
width: 70px;
height: 6px;
background: #6195FF;
display: inline-block;
position: relative;
-webkit-transform: translateX(-50%);
-ms-transform: translateX(-50%);
transform: translateX(-50%);
}

.img-dotted-bg img {
-webkit-box-shadow: 10px 10px 0 0 #eee;
box-shadow: 10px 10px 0 0 #eee;
}


.img-dotted-bg:after {
position: absolute;
content: "";
z-index: -1;
background: url("../images/dotted.jpg") repeat;
bottom: -50px;
right: -50px;
width: 200px;
height: 400px;
}
.slider-item{
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: top center;
height: calc(50vh);
min-height: 500px;
position: relative;
}
.slider-item .slider-text {
    
color: #fff;
height: calc(50vh);
min-height: 500px;
}
.slider-item .slider-text h1 {
font-size: 4rem;
color: #fff;
line-height: 1.2;
font-weight: 700 !important;
text-transform: uppercase;
margin-bottom: 30px;
margin-left: 0.5em;
}
.btn-play-wrap {
position: relative;
height: 100px;
width: 100px;
}
.slider-item .slider-text p {
font-size: 20px;
line-height: 1.5;
font-weight: 300;
color: white;
margin: 0 auto;
}
.btn-play {
display: inline-block;
width: 60px;
height: 60px;
background: #fff;
border-radius: 50%;
position: relative;
position: absolute;
-webkit-transition: .3s all ease-in-out;
-o-transition: .3s all ease-in-out;
transition: .3s all ease-in-out;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}
.btn-play span {
position: absolute;
top: 50%;
left: 50%;
color: #000;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}

.slider-item:before {
content: "";
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: #000;
opacity: .4;
}
.work-thumb {
    display: block;
    position: relative;
    margin-bottom: 30px;
}

.work-thumb:before {
content: "";
background: rgba(0, 0, 0, 0.5);
position: absolute;
-webkit-transition: .3s all ease;
-o-transition: .3s all ease;
transition: .3s all ease;
opacity: 0;
visibility: hidden;
top: 10px;
left: 10px;
right: 10px;
bottom: 10px;
}
.work-thumb .work-text {
text-align: center;
color:white;
position: absolute;
top: 50%;
left: 50%;
width: 90%;
margin-top: 20px;
-webkit-transition: .3s all ease;
-o-transition: .3s all ease;
transition: .3s all ease;
opacity: 0;
visibility: hidden;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}
.work-thumb img {
border: 10px solid #fff;
}
.work-thumb:hover .work-text {
margin-top: 0px;
opacity: 1;
visibility: visible;
}

.work-thumb:hover:before {
opacity: 1;
visibility: visible;
}
.work-thumb .work-text h2 {
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 2rem;
  }
  
  .work-thumb .work-text p {
    color: rgba(255, 255, 255, 0.5);
  }