html{
    scroll-behavior: smooth;
    }
    #about-section{
    background-size: cover;
    }#contact-us {
    background-color: #596065;
    text-align: center;

    padding: 80px 0px;
    }
    #contact-us .section-heading h4 {
    margin-top: 0px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    letter-spacing: 1px;
    }
    #contact-us .section-heading .line-dec {
    width: 270px;
    height: 1px;
    background-color: rgba(250, 250, 250, 0.5);
    margin: 20px auto 15px auto;
    }
    #contact-us .section-heading p {
    color: #fff;
    padding: 0px 33%;
    }
    #contact-us .pop-button {
    margin-top: 30px;
    }
    #contact input {
    border-radius: 0px;
    padding-left: 15px;
    font-size: 13px;
    color: #232323;
    background-color: rgba(250, 250, 250, 1);
    outline: none;
    border: none;
    box-shadow: none;
    line-height: 50px;
    height: 50px;
    width: 100%;
    margin-bottom: 30px;
    }
    #contact textarea {
    border-radius: 0px;
    padding-left: 15px;
    padding-top: 10px;
    font-size: 13px;
    color: #232323;
    background-color: rgba(250, 250, 250, 1);
    outline: none;
    border: none;
    box-shadow: none;
    height: 165px;
    max-height: 220px;
    width: 100%;
    margin-bottom: 25px;
    }
    #contact button {
    display: inline-block;
    background-color: #f7c552;
    font-size: 13px;
    padding: 12px 18px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 20px;
    }
    .pop #contact {
    z-index: 99999;
    position: fixed;
    top: 25%;
    left: 20%;
    right: 20%;
    }
    .pop {
    z-index: 99999;
    text-align: center;
    padding: 30px;
    display:none;
    position:fixed;
    width: 100%;
    top:0;
    left:0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 0px 15px;
    }
    .pop span {
    z-index: 999;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: inline-block;
    line-height: 44px;
    color: #fff;
    text-align: center;
    background-color: #33c1cf;
    }
    .pop span:hover {
    background-color: #596065;
    transition: all 0.5s;
    }
    footer {
    margin-top: -6px;
    background-color: #373a3c;
    padding: 50px 0px;
    }
    footer .logo em {
    font-style: normal;
    font-size: 28px;
    color: #f7c552;
    }
    footer .logo-ft {
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    color: #fff!important;
    font-size: 21px;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: none;
    letter-spacing: 1px;
    }
    footer .logo p {
    font-weight: 300;
    font-size: 12px;
    color: #fff;
    margin-bottom: -10px;
    letter-spacing: 0.5px;
    }
    footer .logo span {
    font-weight: 600;
    color: #f7c552;
    }
    footer .logo a {
    color: #FFF;
    }
    footer .logo a:hover {
    color: #9F0;
    }
    footer .lokasi h4 {
    margin-top: 0px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    }
    footer .lokasi ul li {
    font-size: 13px;
    color: #fff;
    display: inline-block;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    }
    footer .lokasi ul li:first-child {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(250, 250, 250, 0.1);
    }
    footer .contact-info h4 {
    margin-top: 0px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    }
    footer .contact-info ul li {
    font-size: 13px;
    color: #fff;
    display: inline-block;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 300;
    margin-bottom: -15px;
    }
    footer .contact-info ul li em {
    font-style: normal;
    font-weight: 500;
    }
    footer .kasicall h4 {
    margin-top: 0px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 35px;
    }
    footer .kasicall ul li {
    display: inline-block;
    margin-right: 2px;
    }
    footer .kasicall ul li a {
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    color: #373a3c;
    font-size: 15px;
    transition: all 0.3s;
    }
    footer .kasicall ul li a:hover {
    background-color: #f7c552;
    }
    /* RESPONSIVE */
    @media (max-width: 991px){
    .baner-content .text-content p {
    padding: 0px 15%;
    }
    #about .service-item {
    margin-bottom: 80px;
    } 
    #portfolio .section-heading {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #33c1cf;
    height: 505px;
    margin-left: -15px;
    padding-left: 0vh;
    margin-right: -15px;
    padding-right: 0vh;
    overflow: hidden;
    }
    #portfolio .section-heading .line-dec {
    margin: 20px auto 15px auto;
    }
    #portfolio .section-heading p {
    padding: 0px 20px;
    }
    .projects-holder {
    text-align: center;
    padding: 15px 0px;
    }
    #portfolio .project-item {
    margin: 15px 0px;
    }
    #blog .section-heading {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(0, 0, 0, 0.5);
    margin-left: -15px;
    padding-left: 0vh;
    margin-right: -15px;
    padding-right: 0vh;
    overflow: hidden!important;
    }
    #blog .section-heading .line-dec {
    margin: 20px auto 15px auto;
    }
    #blog .section-heading p {
    padding: 0px 30px;
    }
    .tabs {
    padding: 0px 45px;
    }
    #first-tab-group {
    margin-bottom: 30px;
    }
    #contact-us .section-heading p {
    padding: 0px 10%;
    }
    footer {
    text-align: center;
    }
    footer .logo {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
    }
    footer .lokasi {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
    }
    footer .contact-info {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
    }
    footer .contact-info ul li {
    margin-right: 10px;
    margin-left: 10px;
    }
    }
    @media (max-width: 768px){
    #statis{
    padding:20px;
    margin:20px;
    }
    .baner-content .text-content p {
    padding: 0px 10%;
    }
    #main-nav {
    margin-left: -15px;
    margin-right: -15px;
    border-top: none;
    margin-top: 15px;
    background-color: rgba(250, 250, 250, 0.95);
    }
    .navbar-nav {
    margin-top: 0px;
    margin-bottom: 0px;
    }
    .navbar-inverse .navbar-nav>li {
    margin-left: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .navbar-inverse .navbar-nav>li:last-child {
    border-bottom: none;
    }
    .navbar-inverse .navbar-nav>li>a {
    /* font-family: 'Roboto', sans-serif!important; */
    line-height: 55px;
    color: #343434!important;
    }
    .workPrinciple{
    }
    }
    @media (max-width: 385px){
    #statis{
    padding:20px;
    margin:20px;
    }
    footer .lokasi ul li {
    display: block;
    }
    footer .lokasi ul li:first-child {
    margin-right: 0px;
    padding-right: 0px;
    border-right: none;
    }
    }@media only screen and (max-width: 500px) {
    #statis{
    padding:20px;
    margin:20px;
    }
    .imgWebb {
    width: 20%;
    display: flex;
    }
    }
    @media only screen and (max-width: 600px) {
    #statis{
    padding:20px;
    margin:20px;
    }
    .imgWebb {
    width: 20%;
    display: flex;
    }
    .workPrinciple{
    }
    }
    blockquote {
    position: relative;
    padding-left: 30px;
    font-weight: 900;
    color: black;
    padding: 1em;
    margin: 1em;
    max-width: 33em;
    font-size: 20px;
    }
    blockquote:before {
    content: "'";
    /* font-family: serif; */
    position: absolute;
    right: 100%;
    font-size: 70px;
    line-height: 0px;
    top: 50px;
    color: black;
    }
    blockquote:after {
    content: "'";
    display: block;
    position: absolute;
    top: 100%;
    width: 80px;
    height: 5px;
    background: #6195FF;
    left: 1em;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {}
    a,
    a:hover,
    a:focus,
    a:active {
    text-decoration: none;
    outline: none;
    }
    .main-btn:hover{
    color:black  !important;

    }
    a,
    a:active,
    a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
    }
    ul {
    margin: 0;
    padding: 0;
    list-style: none;
    }
    img {
    max-width: 100%;
    height: auto;
    }
    section {
    padding: 60px 0;
    /* min-height: 100vh;*/
    }
    .sec-title{
    position:relative;
    z-index: 1;
    margin-bottom:60px;
    }
    .sec-title .title{
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: #ff2222;
    font-weight: 500;
    margin-bottom: 15px;
    }
    .sec-title h2{
    position: relative;
    display: block;
    font-size:40px;
    line-height: 1.28em;
    color: #222222;
    font-weight: 600;
    padding-bottom:18px;
    }
    .sec-title h2:before{
    position:absolute;
    content:'';
    left:0px;
    bottom:0px;
    width:50px;
    height:3px;
    background-color:#d1d2d6;
    }
    .sec-title .text{
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: #848484;
    font-weight: 400;
    margin-top: 35px;
    }
    .sec-title.light h2{
    color: #ffffff;
    }
    .sec-title.text-center h2:before{
    left:50%;
    margin-left: -25px;
    }
    .list-style-one{
    position:relative;
    }
    .list-style-one li{
    position:relative;
    font-size:16px;
    line-height:26px;
    color: #222222;
    font-weight:400;
    padding-left:35px;
    margin-bottom: 12px;
    }
    .list-style-one li:before {
    content: "\f058";
    position: absolute;
    left: 0;
    top: 0px;
    display: block;
    font-size: 18px;
    padding: 0px;
    color: #ff2222;
    font-weight: 600;
    -moz-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    
    text-rendering: auto;
    line-height: 1.6;
    /* font-family: "Font Awesome 5 Free"; */
    }
    .list-style-one li a:hover{
    color: #44bce2;
    }
    .btn-style-one{
    position: relative;
    display: inline-block;
    font-size: 17px;
    line-height: 30px;
    color: #ffffff;
    padding: 10px 30px;
    font-weight: 600;
    overflow: hidden;
    letter-spacing: 0.02em;
    background-color: #ff2222;
    }
    .btn-style-one:hover{
    background-color: #001e57;
    color: #ffffff;
    }
    .about-section{
    position: relative;
    padding: 120px 0 70px;
    }
    .about-section .sec-title{
    margin-bottom: 45px;
    }
    .about-section .content-column{
    position: relative;
    margin-bottom: 50px;
    }
    .about-section .content-column .inner-column{
    position: relative;
    padding-left: 0px;
    padding-right: 0px;

    }
    .about-section .text{
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 26px;
    color: #848484;
    font-weight: 400;
    }
    .about-section .list-style-one{
    margin-bottom: 45px;
    }
    .about-section .btn-box{
    position: relative;
    }
    .about-section .btn-box a{
    padding: 15px 50px;
    }
    .about-section .image-column{
    position: relative;
    }
    .about-section .image-column .text-layer{
    position: absolute;
    right: -110px;
    top: 50%;
    font-size: 325px;
    line-height: 1em;
    color: #ffffff;
    margin-top: -175px;
    font-weight: 500;
    }
    .about-section .image-column .inner-column{
    position: relative;
    padding-left: 120px;
    padding-bottom: 125px;
    }
    .about-section .image-column .inner-column:before{
    position: absolute;
    left: -75px;
    top: 65px;
    height: 520px;
    width: 520px;
    background-image:url(https://i.ibb.co/fxJ1jtC/about-circle-1.png);
    content: "";
    }
    .about-section .image-column .image-1{
    position: relative;
    }
    .about-section .image-column .image-2{
    position: absolute;
    left: 0;
    bottom: 0;
    }
    .about-section .image-column .image-2 img,
    .about-section .image-column .image-1 img{
    box-shadow: 0 30px 50px rgba(8,13,62,.15);
    }
    .about-section .image-column .video-link{
    position: absolute;
    left: 70px;
    top: 170px;
    }
    .about-section .image-column .video-link .link{
    position: relative;
    display: block;
    font-size: 22px;
    color: #191e34;
    font-weight: 400;
    text-align: center;
    height: 100px;
    width: 100px;
    line-height: 100px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 30px 50px rgba(8,13,62,.15);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    }
    .about-section .image-column .video-link .link:hover{
    background-color: #191e34;
    color: #f
    }
    @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
    .div2 {
    display: grid;
    height: 100vh;
    /* background: -webkit-gradient(linear, left top, right bottom, from(#e9e5e2), to(#d5cecb));
    background: linear-gradient(to bottom right, #e9e5e2, #d5cecb); */
    }
    .page {
    /* display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr); */
    margin: auto;
    background: #fff;
    border-radius: 1.5vw;
    padding: 20px;
    box-shadow: 0 1.5vw 3vw rgba(0, 0, 0, 0.25);
    width: 90%;
    }
    /* .content {
    grid-area: 1 / 1 / 4 / 3;
    padding: 2vw;
    display: grid;
    -webkit-box-align: end;
    align-items: flex-end;
    font: 3vw/1.5 Montserrat, sans-serif;
    } */
    .box {
    width: 100%/6;
    padding-bottom: 100%;
    background: yellow;
    /* -webkit-animation: shape-shifter 10s ease-in-out infinite;
    animation: shape-shifter 10s ease-in-out infinite; */
    }
    @-webkit-keyframes shape-shifter {
    0%,
    100% {
    border-radius: 100% 0 0 0;
    }
    20% {
    border-radius: 0 100% 0 0;
    }
    40% {
    border-radius: 0 0 100% 0;
    }
    60% {
    border-radius: 0 0 0 100%;
    }
    80% {
    border-radius: 100% 100% 100% 100%;
    }
    }
    @keyframes shape-shifter {
    0%,
    100% {
    border-radius: 100% 0 0 0;
    }
    20% {
    border-radius: 0 100% 0 0;
    }
    40% {
    border-radius: 0 0 100% 0;
    }
    60% {
    border-radius: 0 0 0 100%;
    }
    80% {
    border-radius: 100% 100% 100% 100%;
    }
    }
    .box-2 {
    -webkit-animation-delay: -19.8s;
    animation-delay: -19.8s;
    }
    .box-3 {
    -webkit-animation-delay: -6.6s;
    animation-delay: -6.6s;
    }
    .box-4 {
    -webkit-animation-delay: -29.7s;
    animation-delay: -29.7s;
    }
    .box-5 {
    -webkit-animation-delay: -26.4s;
    animation-delay: -26.4s;
    }
    .box-6 {
    -webkit-animation-delay: -13.2s;
    animation-delay: -13.2s;
    }
    .box-7 {
    -webkit-animation-delay: -13.2s;
    animation-delay: -13.2s;
    }
    .box-8 {
    -webkit-animation-delay: -16.5s;
    animation-delay: -16.5s;
    }
    .box-9 {
    -webkit-animation-delay: -26.4s;
    animation-delay: -26.4s;
    }
    .box-10 {
    -webkit-animation-delay: -16.5s;
    animation-delay: -16.5s;
    }
    .box-11 {
    -webkit-animation-delay: -33s;
    animation-delay: -33s;
    }
    .box-12 {
    -webkit-animation-delay: -23.1s;
    animation-delay: -23.1s;
    }
    .box-1 {
    grid-area: 1 / 3 / span 3 / span 4;
    }
    .box-2 {
    grid-area: 1 / 4 / 2 / 5;
    }
    .box-3 {
    grid-area: 1 / 5 / 2 / 6;
    }
    .box-4 {
    grid-area: 1 / 6 / 2 / 7;
    }
    .box-5 {
    grid-area: 2 / 3 / 3 / 4;
    }
    .box-6 {
    grid-area: 2 / 4 / 3 / 5;
    }
    .box-7 {
    grid-area: 2 / 5 / 3 / 6;
    }
    .box-8 {
    grid-area: 2 / 6 / 3 / 7;
    }
    .box-9 {
    grid-area: 3 / 3 / 4 / 4;
    }
    .box-10 {
    grid-area: 3 / 4 / 4 / 5;
    }
    .box-11 {
    grid-area: 3 / 5 / 4 / 6;
    }
    .box-12 {
    grid-area: 3 / 6 / 4 / 7;
    }
    .navy {
    background: #1c5066;
    }
    .cyan {
    background: #8fd2db;
    }
    .tan {
    background: #e5d7bc;
    }
    .pink {
    background: #f4b8b9;
    }
    .orange {
    background: #fa7661;
    }
    .green {
    background: #2d5942;
    }
    .demo{ background: linear-gradient(to right, #4e42b9,#e98223) }
    a:hover,a:focus{
    text-decoration: none;
    outline: none;
    margin:auto;
    }
    #accordion .panel{
    border: none;
    border-radius: 5px;
    box-shadow: none;
    margin-bottom: 10px;
    background: transparent;
    }
    .panel-body p{
    color:black;
    }
    #accordion .panel-heading{
    padding: 0;
    border: none;
    border-radius: 5px;
    background: transparent;
    position: relative;
    }
    #accordion .panel-title a{
    display: block;
    padding: 20px 30px;
    margin: 0;
    background: rgba(0,0,0,0.4);
    font-size: 17px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    border-radius: 5px;
    position: relative;
    }
    /* #accordion .panel-title a.collapsed{ border: none; }
    #accordion .panel-title a:before,
    #accordion .panel-title a.collapsed:before{
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    width: 30px;
    height: 30px;
    line-height: 27px;
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    color: #fff;
    position: absolute;
    top: 15px;
    right: 30px;
    transform: rotate(180deg);
    transition: all .4s cubic-bezier(0.080, 1.090, 0.320, 1.275);
    } */
    #accordion .panel-title a.collapsed:before{
    color: rgba(255,255,255,0.5);
    transform: rotate(0deg);
    }
    #accordion .panel-body{
    padding: 20px 30px;
    background: rgba(0,0,0,0.1);
    font-size: 15px;
    color: #fff;
    line-height: 28px;
    letter-spacing: 1px;
    border-top: none;
    border-radius: 5px;
    }
    .divList {
    width: 100%;
    margin: 10px;
    }
    ol {
    color: #ccc;
    
    list-style-type: none;
    }
    ol li {
    position: relative;
    font: bold italic 35px/1.5 ;
    margin-bottom: 10px;
    }
    li p {
    font: 2rem;
    padding-left: 30px; 
    color: #555;
    display:inline;
    }
    .divSpan {

color:#6195FF;
    }
    @import url(https://fonts.googleapis.com/css?family=Righteous);
  .workPrinciple {
    display: block;
    text-align: center;
    color: rgb(31, 2, 2);
    /* font-family: 'Righteous', serif; */
    /* font-size: 4em; */
    text-shadow: 0.03em 0.03em 0 rgb(1 4 17);
}
    .workPrinciple:after {
    content: attr(data-shadow);
    position: absolute;
    top: .06em; left: .06em;
    z-index: -1;
    text-shadow: none;
    background-image:
    linear-gradient(
    45deg,
    transparent 45%,
    hsla(48,20%,90%,1) 45%,
    hsla(48,20%,90%,1) 55%,
    transparent 0
    );
    background-size: .05em .05em;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shad-anim 15s linear infinite;
    }
    @keyframes shad-anim {
    0% {background-position: 0 0}
    0% {background-position: 100% -100%}
    }
    .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    }
    hr {
margin:10vh;
border-width:10px;
box-shadow:10px 10px lightgray;
transition:1s; }
/* The typing effect */
@keyframes typing {
from { width: 0 }
to { width: 100% }

}

/* The typewriter cursor effect */
@keyframes blink-caret {
from, to { border-color: transparent }
50% { border-color: #6195FF; }
}
.typewriter h2 {
overflow: hidden; /* Ensures the content is not revealed until the animation */
border-right: .15em solid #6195FF; /* The typwriter cursor */
white-space: nowrap; /* Keeps the content on a single line */
margin: 0 auto; /* Gives that scrolling effect as the typing happens */
letter-spacing: .10em; /* Adjust as needed */
animation-iteration-count: infinite;

animation: 
typing 3.5s steps(40, end),
blink-caret .75s step-end infinite;
}